// Generated by Framer (f7d95e4)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {y6beYyThT: {hover: true}};

const serializationHash = "framer-wYWun"

const variantClassNames = {y6beYyThT: "framer-v-1f6fgjb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "y6beYyThT", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 150, pixelWidth: 150, src: "https://framerusercontent.com/images/6VgAN4DN876Onq8i7kGOVP2ec8.svg"}} className={cx(scopingClassNames, "framer-1f6fgjb", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"y6beYyThT"} ref={refBinding} style={{...style}} {...addPropertyOverrides({"y6beYyThT-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wYWun.framer-uokzns, .framer-wYWun .framer-uokzns { display: block; }", ".framer-wYWun.framer-1f6fgjb { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 26px; justify-content: center; padding: 0px; position: relative; width: 26px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wYWun.framer-1f6fgjb { gap: 0px; } .framer-wYWun.framer-1f6fgjb > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-wYWun.framer-1f6fgjb > :first-child { margin-left: 0px; } .framer-wYWun.framer-1f6fgjb > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"g8rmOE7Ez":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZW6XlzdzI: React.ComponentType<Props> = withCSS(Component, css, "framer-wYWun") as typeof Component;
export default FramerZW6XlzdzI;

FramerZW6XlzdzI.displayName = "INSTA";

FramerZW6XlzdzI.defaultProps = {height: 26, width: 26};

addFonts(FramerZW6XlzdzI, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})